import { FaGithubAlt } from "react-icons/fa";
import { RiNpmjsLine } from "react-icons/ri";


export default function Info() {

  return (
    <div className="info">
      <h1 className="title">imagiNEXT</h1>
      <p>
        <b>A visualizer to show Next.js component hierarchy.</b>
      </p>
      <a href="https://www.npmjs.com/package/imaginext">
        <button>
          <RiNpmjsLine className="github-icon" />
          npm
        </button>
      </a>
      <a href="https://github.com/oslabs-beta/imaginext">
        <button>
          <FaGithubAlt className="github-icon" />
          Github
        </button>
      </a>
    </div>
  );
}