import { FaGithubAlt } from "react-icons/fa";
import { RiNpmjsLine } from "react-icons/ri";
import { AiOutlinePlayCircle } from "react-icons/ai";
import img from "../pics/logoBig.png";


export default function Footer(props) {
  return (
    <div className="footer">
      <div className="logo-wrapper">
        <a href="/">
          <img src={img} className="logo" alt="logo" />
        </a>
      </div>
      <button onClick={() => {props.scrollTo("demo");}}>
        <AiOutlinePlayCircle className="github-icon" />
        Demo
      </button>
      <a href="https://www.npmjs.com/package/imaginext">
        <button>
          <RiNpmjsLine className="github-icon" />
          npm
        </button>
      </a>
      <a href="https://github.com/oslabs-beta/imaginext">
        <button>
          <FaGithubAlt className="github-icon" />
          Github
        </button>
      </a>
    </div>
  );
}