
import './App.css';
import TopBar from './components/TopBar';
import Info from './components/Info';
//import Carousel from './components/Carousel';
import Footer from './components/Footer';
// import Badge from './components/Badge';
import Team from './components/Team';
import Demo from './components/Demo';

export default function App() {

  const scrollTo = (id) => {
    const item = document.getElementById(`${id}`);
    item.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <>
      <div className="App">
        <TopBar scrollTo={scrollTo} id='topbar'/>
        <Info />
        {/* <Carousel /> */}
        <Demo />
        <Team />
        {/* <Badge /> */}
      </div>
      <Footer scrollTo={scrollTo}/>
     </>
  );
}
