


export default function Member(props) {
    const {name, location, title, imgUrl, linkedInUrl} = props.member;

    return (
      <a href={linkedInUrl}>
        <div className="member-card">
          <img className="member-image" src={imgUrl} alt={name}></img>
          <b><p>{name}</p></b>
          <p>{title}</p>
          <p>{location}</p>
        </div>
      </a>
    );
  }