import DemoCard from "./DemoCard";
import cli from "../pics/cli_noline.gif";
import hover from "../pics/HoverToEachNodeToSeeFilePathPropsAndDataRenderMethod.gif";
import generate from "../pics/GenerateATreeGraphToShowAppFileStructure.gif";

import { GrInstallOption } from "react-icons/gr";
import { TbBinaryTree2 } from "react-icons/tb";
import { FaRegListAlt } from "react-icons/fa";

const features = [
  {
    description: "Install npm package through CLI",
    url: cli,
    icon: <GrInstallOption />,
  },
  {
    description: "Generate a tree graph to show app file structure",
    url: generate,
    icon: <TbBinaryTree2 />,
  },
  {
    description: "Display prop drilling",
    url: hover,
    icon: <FaRegListAlt />,
  },
];

export default function Demo() {

    const demoArr = [];
    // let switch = true;
    features.forEach((v, i) => {
        // switch = !switch;
        demoArr.push(<DemoCard feature={v} key={i} id={ 'item' + i} i={i} />)
    })

    return (
        <>
            <div className="demo-container">
                {demoArr}
            </div>
        </>
    )

}