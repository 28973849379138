/** @format */

import { GrInstallOption } from "react-icons/gr";
import { TbBinaryTree } from "react-icons/tb";
import { FaRegListAlt } from "react-icons/fa";

const iconArr = [<GrInstallOption />, <TbBinaryTree />, <FaRegListAlt />];

export default function DemoCard({ feature, id, i }) {
  const { description, url} = feature;
  
  return (
    <>
      <div className="democard-container" id={id}>
        <div className="container-column column1">
          <img src={url} alt=""></img>
        </div>
        <div className="container-column column2">
          <div className="line"></div>
        </div>
        <div className="container-column column3">
          <span className="democard-icon">{iconArr[i]}</span>

          <p className="democard-description">{description}</p>
        </div>
      </div>
    </>
  );
}
