
import Member from "./Member";

const team = [
  {
    name:'Leo Raker',
    location: 'New Jersey',
    title:'COO of GameSphere',
    imgUrl:'https://ca.slack-edge.com/T037PK0BFFE-U03JAUQH0CW-ea626667013b-512',
    linkedInUrl: "https://www.linkedin.com/in/leo-raker-4a11b4262/"
  },
  {
    name:'Richter Lau',
    location: 'New York',
    title:'Software Engineer',
    imgUrl:'https://ca.slack-edge.com/T037PK0BFFE-U03FKP6EJVB-14390a3be95b-512',
    linkedInUrl: ""
  },
  {
    name:'Holly Nigh',
    location: 'California',
    title:'Software Engineer',
    imgUrl:'https://ca.slack-edge.com/T037PK0BFFE-U038LAYJ3GR-dea1ed43cdd0-512',
    linkedInUrl: "https://github.com/hollynigh"
  },
  {
    name:'Sammie Huang',
    location: 'Washington D.C.',
    title:'Software Engineer',
    imgUrl:'https://ca.slack-edge.com/T037PK0BFFE-U03AFDK7542-3353e1c724ce-512',
    linkedInUrl: "https://www.linkedin.com/in/sammiehuang88"
  }
]

export default function Team() {
  const membersArray = []
  
  team.forEach((v,i) => {
    membersArray.push(<Member id={"item" + i} key={i} member={v} />);
  });

  return (
    <div id="team">{membersArray}</div>
  );
}
