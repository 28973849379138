import img from "../pics/logoBig.png";
import { RiNpmjsLine } from "react-icons/ri";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { RiOpenArmLine } from "react-icons/ri";

export default function TopBar(props) {

  return (
    <div className="top-bar" data-testid="top-bar">
      <div className="top-bar-container">
        <a href="/">
          <img src={img} className="logo" alt="logo" />
        </a>
        <a href="https://www.npmjs.com/package/imaginext">
          <button>
            <RiNpmjsLine className="github-icon" />
            npm
          </button>
        </a>
        <button
          onClick={() => {
            props.scrollTo("demo");
          }}
        >
          <AiOutlinePlayCircle className="github-icon" />
          Demo
        </button>
        <button
          onClick={() => {
            props.scrollTo("team");
          }}
        >
          <RiOpenArmLine className="github-icon" />
          Meet The Team
        </button>
      </div>
    </div>
  );
}
